import React from 'react';
import styles from './ContactsContent.module.css'
import ContactsData from './ContactsData/ContactsData';

const ContactContent = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Контакты</h2>
            <div className={styles.contacts}>
                <ContactsData
                    title='Заказать проект:'
                    whatsaap='WhatsApp/Telegram:'
                    phone='8 (499) 973-96-75'
                    email='E-mail:'
                    emailInfo='e.ovsepyan@webinfra.ru'
                />
                <ContactsData
                    title='Мы оффлайн:'
                    legalInformation='Юридические данные:'
                    name='ООО “ВЕБ Инфраструктура”'
                    adress='Наш адрес:'
                    adressInfo='111024, г. Москва, вн.тер.г. муниципальный округ Лефортово, ул.  Авиамоторная, д. 50 стр. 2, офис 164'
                    INN='ИНН/КПП'
                    INNInfo='7722486230/772201001'
                    OKPO='ОКПО'
                    OKPOInfo='43801226'
                    OGRN='ОГРН'
                    OGRNInfo='1207700118713'
                />
                <ContactsData
                    title='Написать директору:'
                    whatsaap='WhatsApp/Telegram:'
                    phone='+7 (916) 772-29-20'
                    email='E-mail:'
                    emailInfo='a.smirnov@webinfra.ru'
                />
                {/* <ContactsData
                    title='Соцсети:'
                    instagram='Instagram:'
                    account='@web_infra'
                /> */}
            </div>
        </div>
    )
}

export default ContactContent
