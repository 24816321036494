import React from 'react';
import styles from './ServicesCard.module.css';
import { createRef } from 'react';

const ServicesCard = (props) => {

    const front = createRef();
    const back = createRef();

    const flipCard = () => {

        if (screenSize.matches) {

            front.current.style.transform = front.current.style.transform === 'rotateY(180deg)' ? 'rotateY(360deg)' : 'rotateY(180deg)';
            back.current.style.transform = back.current.style.transform === 'rotateY(360deg)' ? 'rotateY(180deg)' : 'rotateY(360deg)'
        }

    }

    const screenSize = window.matchMedia("(max-width: 768px)")

    return (
        <div>
            <div className={styles.card} onClick={flipCard}>
                <div className={styles.front} ref={front} style={{ backgroundImage: props.image, backgroundColor: props.color }}><h4 className={styles.title} style={{ borderBottom: props.bottom, paddingBottom: props.paddingBottom, width: props.width }}>{props.title}</h4></div>
                <div className={styles.back} ref={back} style={{ backgroundColor: props.color }}>
                    <h4 className={styles.backTitle}>{props.title}</h4>
                    <p className={styles.description}>{props.description}</p>
                </div>
            </div>
        </div>)

}

export default ServicesCard