import React from 'react';
import styles from './HeaderLogo.module.css'
import { NavLink } from 'react-router-dom';

import logo from './logo.svg'
import mobileLogo from './mobileLogo.svg'

const HeaderLogo = () => {
    return (
       <NavLink to='/' >
            <div className={styles.logoWrapper}>
                <img src={logo} alt='logo'/>
                <img src={mobileLogo} alt='logo'/>
            </div>
        </NavLink>
    )
}

export default HeaderLogo
