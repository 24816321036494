import React from 'react';
import styles from './Projects.module.css';

import ProjectsHeading from "./ProjectsHeading/ProjectsHeading";
import ProjectsText from "./ProjectsText/ProjectsText";
import ProjectsButtons from "./ProjectsButtons/ProjectsButtons";
import ProjectsCards from "./ProjectsCards/ProjectsCards";


const Projects = () => {

    return (
        <section id='sectionProjects' className={styles.projects}>
            <ProjectsHeading />
            <ProjectsText />
            <ProjectsCards />
            <ProjectsButtons />
        </section>
    )
}

export default Projects