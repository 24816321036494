import React from 'react'
import styles from './Loader.module.css'

function Loader() {
    return (
        <div
            style={{
                justifyContent: 'center',
                display: 'flex',
                margin: '5rem',
            }}
        >
            <div className={styles.ldsDualRing}></div>
        </div>
    )
}

export default Loader
