import React, {useState } from 'react';
import ProjectsMain from "../../components/ProjectMain/ProjectMain";

import ScrollToTopOnMount from "../../scrollToTopOnMount";
import ProjectsHeader from "../../components/ProjectHeader/ProjectHeader";

const ProjectsPage = () => {

    const [companyResults, setCompanyResults] = useState([]);
    const [companySolutions, setCompanySolutions] = useState([]);
    const [companyProjectTasks, setCompanyProjectTasks] = useState([]);


    function updateData (companyResultsValue, companySolutionsValue, companyProjectTasksValue) {
        setCompanyResults(companyResultsValue);
        setCompanySolutions(companySolutionsValue);
        setCompanyProjectTasks(companyProjectTasksValue);
    }

    return (

        <div>
            <ScrollToTopOnMount />
            <ProjectsHeader updateData={updateData} />
            <ProjectsMain
                companyProjectTasks={companyProjectTasks} companySolutions={companySolutions} companyResults={companyResults}
            />
        </div>
    )
}
export default ProjectsPage