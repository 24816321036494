import React from 'react';
import styles from './ContactsHeader.module.css';
import commonStyles from "../../style/common.module.css";

const ContactsHeader = () => {
    return (
        <section className={commonStyles.main}>
            <div className={commonStyles.mainWrapper}>
                <div className={styles.headerSection}></div>
            </div>
        </section>
    )
}

export default ContactsHeader