import React, {useContext} from 'react';
import styles from './HeaderMenu.module.css'
import Context from '../../../../context/context'

import { NavLink } from 'react-router-dom';
import { createRef } from 'react';
import getServices from '../../../../API/getServices'
import ServicesList from '../../../../data/services/servicesList'

const HeaderMenu = () => {
    const burgerMenuRef = createRef();
    
    const { 
        urlGetServices,
        setLoadingStatusForFirstStepSectionData, 
        setSecondStepSectionData,
    } = useContext(Context)

    const removeChecked = () => {
        burgerMenuRef.current.checked = false
    }
    
    const handleClick = () => {
        setLoadingStatusForFirstStepSectionData(true);
        
        getServices(
            urlGetServices,
            setLoadingStatusForFirstStepSectionData,
            ServicesList,
            setSecondStepSectionData,
        );
    }

    return (
        <>
            <nav className={styles.nav}>
                <NavLink activeClassName={styles.active} to='/Contacts' className={styles.navLink}>Контакты</NavLink>
                <NavLink activeClassName={styles.active} to='/Team' className={styles.navLink}>Команда</NavLink>
                <NavLink activeClassName={styles.active} to='/Projects' className={styles.navLink}>Проекты</NavLink>
                <NavLink activeClassName={styles.active} to='/Documents' className={styles.navLink}>Документы</NavLink>
                <NavLink activeClassName={styles.active} to='/Gratitudes' className={styles.navLink}>Благодарности</NavLink>
                {/*<NavLink activeClassName={styles.active} onClick={handleClick} to='/Calculator' className={styles.navLink}>Калькулятор</NavLink>*/}
            </nav>


            <nav className={styles.burgerNav} role="navigation">
                <div id={styles.menuToggle}>

                    <input ref={burgerMenuRef} type="checkbox" />

                    <span></span>
                    <span></span>
                    <span></span>

                    <ul id={styles.menu}>
                        <NavLink onClick={removeChecked} to='/Contacts'><li>Контакты</li></NavLink>
                        <NavLink onClick={removeChecked} to='/Projects'><li>Проекты</li></NavLink>
                        <NavLink onClick={removeChecked} to='/Team'><li>Команда</li></NavLink>
                        <NavLink onClick={removeChecked} to='/Documents'><li>Документы</li></NavLink>
                        <NavLink onClick={removeChecked} to='/Gratitudes'><li>Благодарности</li></NavLink>
                        {/*<NavLink onClick={removeChecked} to='/Calculator'><li>Калькулятор</li></NavLink>*/}
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default HeaderMenu
