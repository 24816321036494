import React from 'react'
import styles from './DocsMain.module.css'

import DocMainCardsContainer from "./DocMainCardsContainer/DocMainCardsContainer";

const DocsMain = () => {

    return (
        <section className={styles.teamInfo}>
            <DocMainCardsContainer/>
        </section>
    )
}

export default DocsMain

