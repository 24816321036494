import React, {useEffect, useState} from 'react'
import axios from "axios"
import styles from './GratMainCardsContainer.module.css'
import Docs from "./img/docs.png";

import GratMainCard from './GratMainCard/GratMainCard';


const GratMainCardsContainer = (props) => {
    const [grat, setGrat] = useState([]);

    useEffect(() => {
        axios.get("https://api.webinfra.ru/acknowledgments")
            .then((res) => {
                setGrat(res.data.results);
            });
    }, []);

    console.log(grat)

    const gratsCards = grat.map((el) => {
        return (
            <GratMainCard title={el.title} document={el.document} src={Docs} key={el.id} />
        )
    })

    return (
        <div className={styles.mainWrapper}>
            <h2 className={styles.title}>Благодарности</h2>
            <div className={styles.wrapper} >
                {gratsCards}
            </div>
        </div>
    )

}

export default GratMainCardsContainer

