import React from 'react';
import styles from './ContactsData.module.css'

const ContactsData = (props) => {
    return (
        <div>
            <h5 className={styles.title}>{props.title}</h5>
            <p className={styles.subtitle}>{props.whatsaap || props.legalInformation || props.instagram}</p>
            <p className={styles.description}>{props.phone || props.name || props.account}</p>

            <p className={styles.subtitle}>{props.email || props.adress}</p>
            <p className={styles.description}>{props.emailInfo || props.adressInfo}</p>

            <p className={styles.subtitle}>{props.INN}</p>
            <p className={styles.description}>{props.INNInfo}</p>
            <p className={styles.subtitle + ' ' + styles.indent}>{props.OKPO}</p>
            <p className={styles.description}>{props.OKPOInfo}</p>
            <p className={styles.subtitle + ' ' + styles.indent}>{props.OGRN}</p>
            <p className={styles.description}>{props.OGRNInfo}</p>
        </div>
    )
}

export default ContactsData