import React from 'react';

import styles from '../FooterLinks/FooterLinks.module.css'

const FooterCEO = (props) => {
    return (

        <div>
            <div className={styles.item}>Мы занимаемся разработкой IT решений, нейронных сетей, приложений, микросервисов для российских компаний</div>
            <div className={styles.item}>Сплоченная команда специалистов из лучших ВУЗов РФ позволяет предлагать услуги высокого качества по удивительно низким и «вкусным» ценам.</div>
        </div>


    )
}

export default FooterCEO
