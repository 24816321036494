import React from 'react';
import styles from './GratMainCard.module.css';

const GratMainCard = (props) => {
    return (
        <a href={props.document} className={styles.bioInfoContainer}>
                <img src={props.src} />
                <h2 className={styles.fullName}>
                    {props.title}
                </h2>
        </a>
    )
}


export default GratMainCard

