import React from 'react';
import styles from './ProjectsCards.module.css';

import fastExchange from '../../../pages/Main/images/projects/fastExchange.png';
import globax from '../../../pages/Main/images/projects/globax.png';
import McKinseyHack from '../../../pages/Main/images/projects/McKinseyHack.png';
import McKinseyHR from '../../../pages/Main/images/projects/McKinseyHR.png';
import noname from '../../../pages/Main/images/projects/noname.png';
import proil from '../../../pages/Main/images/projects/proil.png';


const ProjectsCards = () => {

    const cardsData = [

        { image: proil},
        { image: globax },
        { image: noname },
        { image: fastExchange },
        { image: McKinseyHack },
        { image: McKinseyHR }
    ]

    let renderCards = cardsData.map((el, index) => {

        return <div style={{padding: '0 0 20px'}} key={index}> <img style={{width: '100%'}} src={el.image} alt=''/> </div>
    });


    return (
            <div className={styles.cards}>
                {renderCards}
            </div>
    )
}

export default ProjectsCards