import ScrollToTopOnMount from "../../scrollToTopOnMount";
import React from "react";
import DocsMain from "../../components/DocsMain/DocsMain";
import Footer from "../../components/Footer/Footer";
import DocsHeader from "../../components/DocsHeader/DocsHeader";

const DocumentsPage = () => {
    return (
        <>
            <ScrollToTopOnMount />
            <DocsHeader />
            <DocsMain />
            <Footer />
        </>
    )
}

export default DocumentsPage