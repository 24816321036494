import React from 'react'

// export default function SericesList()
// {
// return (

// )
// }

// class ServicesList {
//   constructor(list) {
// this.serviceList = list
//   }
// }

// Object.defineProperties(ServicesList.prototype, {
//   serviceList: {
//     get() {
//       return this._serviceList
//     },
//     set(list) {
//       this._serviceList = list
//     },
//   },
// })

class ServicesList {
  static servicesList = []
}

export default ServicesList
