import React from 'react'
import styles from './GratMain.module.css'

import GratMainCardsContainer from "./GratMainCardsContainer/GratMainCardsContainer";

const GratMain = () => {

    return (
        <section className={styles.teamInfo}>
            <GratMainCardsContainer/>
        </section>
    )
}

export default GratMain

