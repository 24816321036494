import React from 'react';

import Footer from '../../components/Footer/Footer';
import ContactsMain from '../../components/ContactsMain/ContactsMain';
import ScrollToTopOnMount from "../../scrollToTopOnMount";
import ContactsHeader from "../../components/ContactsHeader/ContactsHeader";

const ContactsPage = () => {

    return (
        <>
            <ScrollToTopOnMount />
            <ContactsHeader />
            <ContactsMain />
            <Footer />
        </>
    )
}

export default ContactsPage