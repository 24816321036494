import React from 'react';
import styles from './DocMainCard.module.css';

const DocMainCard = (props) => {
    return (
        <a href={props.document} className={styles.bioInfoContainer}>
            <img src={props.src} />
            <h2 className={styles.fullName}>
                {props.title}
            </h2>
        </a>
    )
}


export default DocMainCard

