import React from 'react';

import styles from './FooterInfo.module.css'
import {HashLink as Link} from "react-router-hash-link";

const FooterInfo = () => {
    return (
        <div>
            <h5 className={styles.title}>Есть вопросы?</h5>

            <p className={styles.item}>8 (499) 973-96-75</p>
            <p className={styles.itemDescription}>Узнать стоимость разработки</p>

            <p className={styles.item}>a.smirnov@webinfra.ru</p>
            <Link to='/#sectionContactUs'><p className={styles.itemDescription}>Написать нам</p></Link>
        </div>
    )
}

export default FooterInfo
