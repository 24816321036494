import React from 'react';
import style from '../../../style/common.module.css';

const ServicesHeading = () => {

    return (
            <h2 className={style.title + ' ' + style.blackWord}>Чем мы можем быть Вам полезны?</h2>
    )
}

export default ServicesHeading