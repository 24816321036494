import React from 'react';

import styles from './FooterLinks.module.css'

import instIcon from '../Images/instIcon.png'
import {HashLink as Link} from "react-router-hash-link";

const FooterLinks = (props) => {
    return (

        <div>
            <Link  to='/Contacts'> <div className={styles.item}>{props.contacts}</div> </Link>
            <Link  to='/Team'> <div className={styles.item}>{props.team}</div></Link>
            <Link to='/#sectionPartners'> <div className={styles.item}>{props.partners}</div></Link>
            <a target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/web_infra/'>
            <div className={styles.item}>
                <img className={styles.img} src={instIcon} alt='icon' />
                {props.instagram}
            </div>
            </a>
        </div>


    )
}

export default FooterLinks