import React from 'react';
import commonStyle from '../../../style/common.module.css';
import styles from './MainHeaderButtons.module.css'

import { HashLink as Link } from 'react-router-hash-link';
import smoothscroll from 'smoothscroll-polyfill';



const MainHeaderButtons = () => {
    smoothscroll.polyfill();
    return (
        <div className={commonStyle.buttonsContainer}>
            <Link smooth to='/#sectionContactUs'><button onClick={e => { e.preventDefault() }} className={commonStyle.buttonWhite + ' ' + styles.buttonBoxShadow}>Заказать оценку проекта</button></Link>
            <Link smooth to='/#sectionProjects'><button onClick={e => { e.preventDefault() }} className={commonStyle.button + ' ' + styles.buttonBoxShadow}>Смотреть наши проекты</button></Link>
        </div>
    )
}

export default MainHeaderButtons
