import React, { useEffect, useRef }  from 'react';
import styles from './Header.module.css'

import HeaderLogo from "./Components/HeaderLogo/HeaderLogo";
import HeaderMenu from "./Components/HeaderMenu/HeaderMenu";
import HeaderButton from './Components/HeaderButton/HeaderButton';

const Header = () => {

    const headerRef = useRef();
    const wrapperRef = useRef();

    function fixedMenu(){
        if (window.pageYOffset > headerRef.current.clientHeight) {
            headerRef.current.style.backgroundColor = '#292A31'
            headerRef.current.style.position = 'fixed'
            wrapperRef.current.style.margin = '0 auto'
        }
        else {
            wrapperRef.current.removeAttribute('style')
            headerRef.current.style.backgroundColor = 'transparent'
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', fixedMenu); 

        return () => {
            window.removeEventListener('scroll', fixedMenu);
        }

      }, []);
    
    return (
        <div className={styles.header} ref={headerRef} style={{ backgroundColor: 'transparent', position: 'absolute' }}>
            <div className={styles.wrapper} ref={wrapperRef} >
                <HeaderLogo />
                <HeaderButton />
                <HeaderMenu />
            </div>
        </div>
    )
}

export default Header