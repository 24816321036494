import React from 'react';
import styles from './Partners.module.css';

import PartnersHeading from "./PartnersHeading/PartnersHeading";
import PartnersLogos from "./PartnersLogos/PartnersLogos";

const Partners = () => {

    return (
        <section id='sectionPartners' className={styles.partners}>
            <PartnersHeading />
            <PartnersLogos />
        </section>
    )
}

export default Partners