import axios from 'axios'

function getServiceRecursive(
  setLoadingStatus,
  ServicesListClassObject,
  url,
  servicesListLocal
) {
  console.log(url)
  axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      response.data.results.map((element) => {
        servicesListLocal.push(element)
      })

      if (response.data.next === null) {
        ServicesListClassObject.servicesList = servicesListLocal
        setLoadingStatus(false)
        return
      }

      getServiceRecursive(
        setLoadingStatus,
        ServicesListClassObject,
        response.data.next,
        servicesListLocal
      )
    })
    .catch((error) => {
      console.log(error)
    })
}

function getServices(url, setLoadingStatus, ServicesListClassObject) {
  let servicesListLocal = []
  getServiceRecursive(
    setLoadingStatus,
    ServicesListClassObject,
    url,
    servicesListLocal
  )
}

export default getServices
