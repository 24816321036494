import React, {useEffect, useState} from 'react'
import axios from "axios"
import styles from './TeamMainCardsContainer.module.css'

import AntonSrcSet from '../../../pages/Team/images/srcSet/Anton.png'
import IvanSrcSet from '../../../pages/Team/images/srcSet/IvanSrcSet.png'
import EricSrcSet from '../../../pages/Team/images/srcSet/EricSrcSet.png'
import DenisSrcSet from '../../../pages/Team/images/srcSet/DenisSrcSet.png'
import AlexSrcSet from '../../../pages/Team/images/srcSet/AlexSrcSet.png'
import MaximSrcSet from '../../../pages/Team/images/srcSet/MaximSrcSet.png'

import TeamMainCard from './TeamMainCard/TeamMainCard';


const TeamMainCardsContainer = (props) => {
    const [team, setTeam] = useState([]);

    useEffect(() => {
        axios.get("https://api.webinfra.ru/command")
            .then((res) => {
                setTeam(res.data.results);
            });
    }, []);

    console.log(team)

    const teamsProfiles = team.map((el, index) => {
        return (
            <TeamMainCard fullName={el.full_name} workPosition={el.work_position} about={el.about} image={el.photo} srcSet={el.photo} key={el.id} />
        )
    })

    return (
        < div className={styles.wrapper} >
            {teamsProfiles}
        </div>
    )

}

export default TeamMainCardsContainer

