import ScrollToTopOnMount from "../../scrollToTopOnMount";
import React from "react";
import GratMain from "../../components/GratitudesMain/GratMain";
import Footer from "../../components/Footer/Footer";
import GratsHeader from "../../components/GratsHeader/GratsHeader";

const GratitudesPage = () => {
    return (
        <>
            <ScrollToTopOnMount />
            <GratsHeader />
            <GratMain />
            <Footer />
        </>
    )
}

export default GratitudesPage