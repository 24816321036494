import React, { useState } from 'react'
import './WI.css'
import { Route } from 'react-router-dom'
import Header from './components/Header/Header'
import MainPage from './pages/Main/MainPage'
import TeamPage from './pages/Team/TeamPage'
import ContactsPage from './pages/Contacts/ContactsPage'
import ProjectsPage from './pages/Projects/ProjectsPage'
import CalculatorPage from './pages/Calculator/CalculatorPage'
import Context from './context/context'
import Urls from './API/urls'
import DocumentsPage from "./pages/Documents/DocumentsPage";
import GratitudesPage from "./pages/Gratitudes/GratitudesPage";

const WI = () => {
  const [
    loadingStatusForFirstStepSectionData,
    setLoadingStatusForFirstStepSectionData,
  ] = useState(true)

  return (
    <Context.Provider
      value={{
        urlGetServices: Urls.urlGetServices,
        urlPostFormData: Urls.urlPostFormData,
        urlPostPollAnswers: Urls.urlPostPollAnswers,
        loadingStatusForFirstStepSectionData,
        setLoadingStatusForFirstStepSectionData,
      }}
    >
      <div className="Wrapper">
        <Header />
        <Route exact path="/" render={() => <MainPage />}></Route>
        <Route path="/Team" render={() => <TeamPage />}></Route>
        <Route path="/Documents" render={() => <DocumentsPage />}></Route>
        <Route path="/Gratitudes" render={() => <GratitudesPage />}></Route>
        <Route path="/Projects" render={() => <ProjectsPage />}></Route>
        <Route path="/Contacts" render={() => <ContactsPage />}></Route>
        {/*<Route path="/Calculator" render={() => <CalculatorPage />}></Route>*/}
      </div>
    </Context.Provider>
  )
}

export default WI
