import React from 'react';
import styles from './TeamHeader.module.css';
import commonStyles from '../../style/common.module.css';

const TeamHeader = () => {
    return (

        <section className={commonStyles.main}>
            <div className={commonStyles.mainWrapper}>
                <div className={styles.wrapper}>
                    <h2 className={styles.title}>Наша команда</h2>
                    <p className={styles.aboutInfo}>Мы – команда амбициозных разработчиков компании WEB  Инфраструктура. </p>
                    <p className={styles.moreAboutInfo}>Наши проекты – это наше лицо, каждый из них сделан на совесть и нам не стыдно их показать. Мы очень увлечены своим делом, работоспособны и готовы к любым вызовам и трудностям.</p>
                </div>
            </div>
        </section>


    )
}

export default TeamHeader