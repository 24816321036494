import React from 'react';

import commonStyle from '../../../style/common.module.css';


const PartnersHeading = () => {

    return (
            <h2 className={commonStyle.title + ' ' + commonStyle.blackWord}>Наши клиенты</h2>
    )
}

export default PartnersHeading
