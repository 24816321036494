import React from 'react';
import styles from './Services.module.css';

import ServicesHeading from "./ServicesHeading/ServicesHeading";
import ServicesCardsContainer from "./ServicesCardsContainer/ServicesCardsContainer";

const Services = () => {

    return (
        <section className={styles.services}>
            <ServicesHeading />
            <ServicesCardsContainer />
        </section>
    )
}

export default Services