import React from 'react'
import axios from 'axios'

function postPollAnswers(url, data) {
  // console.log('in postPollAnswers')
  console.log('in postPollAnswers', data)

  axios({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then(() => {
      console.log('POST executed')
    })
    .catch((error) => {
      console.log(error)
    })
}

export default postPollAnswers
