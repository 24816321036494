import React from 'react';

import { YMaps, Map, Placemark, TrafficControl, ZoomControl, GeolocationControl } from 'react-yandex-maps';

import styles from './ContactsMap.module.css'

const ContactsMap = () => {

    const mapData = {
        center: [55.764688, 37.719828],
        zoom: 12,
    };

    return (
        <YMaps>
            <div className={styles.wrapper}>

                {/* <iframe title='map' src="https://yandex.ru/map-widget/v1/?um=constructor%3Aed23f8401ec39d57d9c3b42f6f84eeb8c4c920f7b9c847c6afd74a5470239c18&amp;source=constructor" width="100%" height="250" frameBorder="0"></iframe> */}

                <Map width='100%' defaultState={mapData}>

                    <Placemark
                        geometry={[55.764688, 37.719828]}

                        options={{
                            preset: 'islands#grayPocketIcon',
                            hasBalloon: true,
                            hasHint: true,
                            openEmptyBalloon: true

                        }}
                        properties={{
                            balloonContent: '111024, г. Москва, вн.тер.г. муниципальный округ Лефортово, ул.  Авиамоторная, д. 50 стр. 2, офис 164'
                        }}
                        modules={
                            ['geoObject.addon.balloon', 'geoObject.addon.hint']
                        }
                    />
                    <TrafficControl options={{ float: 'right' }} />
                    <ZoomControl options={{ float: 'right' }} />
                    <GeolocationControl options={{ float: 'left' }} />
                </Map>
                <div className={styles.border}></div>
            </div>
        </YMaps>

    )
}

export default ContactsMap
