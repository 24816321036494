import React from 'react';
import styles from './MainHeaderText.module.css';

const MainHeaderText = () => {
    return (
            <div className={styles.mainText}>
                <h2 className={styles.title}>Разрабатываем и внедряем IT решения</h2>
                <h4 className={styles.subtitle}>Решаем Ваши бизнес-задачи с помощью эффективных  IT-решений</h4>
            </div>
    )
}

export default MainHeaderText