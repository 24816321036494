import React from 'react';

import styles from './MainHeader.module.css';
import commonStyles from '../../style/common.module.css';

import MainHeaderText from "./MainHeaderText/MainHeaderText";
import MainHeaderButtons from "./MainHeaderButtons/MainHeaderButtons";

const MainHeader = () => {
    return (

        <section className={commonStyles.main}>
            <div className={commonStyles.mainWrapper}>
                <div className={styles.wrapper}>
                    <MainHeaderText />
                    <MainHeaderButtons />
                </div>
            </div>
        </section>


    )
}

export default MainHeader