import React from 'react';
import styles from './ProjectsButtons.module.css';

const ProjectsButtons = () => {

    return <div className={styles.container}>

        <a target='_blank' href='https://api.webinfra.ru/static/webinfra_img/WEB-Infra.pdf' rel="noopener noreferrer" ><div className={styles.getPresentation}>Скачать презентацию</div></a>
    </div>

}

export default ProjectsButtons
