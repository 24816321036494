import React from 'react'
import styles from './TeamMain.module.css'

import TeamMainCardsContainer from "./TeamMainCardsContainer/TeamMainCardsContainer";

const TeamMain = () => {

    return (
        <section className={styles.teamInfo}>
            <TeamMainCardsContainer/>
        </section>
    )
}

export default TeamMain

