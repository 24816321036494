import React from 'react';

import TeamMain from '../../components/TeamMain/TeamMain';
import Footer from '../../components/Footer/Footer';
import ScrollToTopOnMount from "../../scrollToTopOnMount";
import TeamHeader from "../../components/TeamHeader/TeamHeader";

const TeamPage = () => {

    return (
        <>
            <ScrollToTopOnMount />
            <TeamHeader />
            <TeamMain />
            <Footer />
        </>
    )
}

export default TeamPage