import React from 'react';

import Projects from '../../components/Projects/Projects';
import Partners from '../../components/Partners/Partners';
import ContactUs from '../../components/ContactUs/ContactUs';
import Services from "../../components/Services/Services";

import Footer from "../../components/Footer/Footer";
import ScrollToTopOnMount from "../../scrollToTopOnMount";
import MainHeader from "../../components/MainHeader/MainHeader";

const MainPage = () => {

    return (

        <div>
            <ScrollToTopOnMount />
            <MainHeader/>
            <Services />
            <Projects />
            <Partners />
            <ContactUs />
            <Footer />
        </div>

    )
}

export default MainPage