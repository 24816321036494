import React from 'react';

import styles from './ContactsMain.module.css'

import ContactsMap from '../ContactsMap/ContactsMap';
import ContactContent from '../ContactsContent/ContactsContent';

const ContactsMain = () => {
    return (
        <div className={styles.wrapper}>
            <ContactContent />
            <ContactsMap />
        </div>
    )
}

export default ContactsMain