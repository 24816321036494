import React from 'react';
import styles from './ProjectMain.module.css'
import Footer from "../Footer/Footer";

const ProjectsMain = (props) => {

    const regex = /\\n|\\r\\n|\\n\\r|\\r/g;

        let renderTasks = props.companyProjectTasks.map((el, index)=>{ return <div key={index}>
            <div className={styles.container}>
                <p className={styles.count}>{index + 1}</p>
                <div className={styles.tasksDescription}>
                    <p>{el.title}</p>
                    <p>{(el.description).replace(regex, '')}</p>
                </div>
            </div>
            <div className={styles.line}></div>
        </div>})

        let renderSolutions = props.companySolutions.map((el, i) => { return <div key={i} className={styles.container + ' ' + styles.solutionsContainer}>
            <div className={styles.solutionsDescription}>
                <p>{el.title}</p>
                <p>{(el.description).replace(regex, '')}</p>
            </div>
            <img className={styles.solutionImage} src={el.href} alt='img' />
        </div>
        })

        let renderResults = props.companyResults.map((el, index) =>{

            if (index % 2 === 0) {

                return <div key={index} className={styles.container + ' ' + styles.resultsContainer}>
                    <p className={styles.shortText}>{el.title}</p>
                    <p className={styles.longText}>{(el.description).replace(regex, '')}</p>
                </div>
            }
            else { return <div key={index} className={styles.container + ' ' + styles.resultsContainer}>
                <p className={styles.longText}>{(el.description).replace(regex, '')}</p>
                <p className={styles.shortText}>{el.title}</p>
            </div>
            }
        })


        return (

            <div className={styles.wrapper}>

                <section >
                    <div className={styles.tasksWrapper}>
                        <h3 className={styles.title}>Задачи проекта</h3>
                        {renderTasks}
                    </div>
                </section>

                <section className={styles.solutions}>
                    <div className={styles.ourSolutionsWrapper}>
                        <h3 className={styles.title + ' ' + styles.titleWhite}>Наши решения</h3>
                        {renderSolutions}
                    </div>
                </section>

                <section>
                    <div className={styles.results}>
                        <h3 className={styles.title}>Результаты</h3>
                        <div>
                            {renderResults}
                        </div>
                    </div>
                </section>
            <Footer />
            </div>
        )
}
export default ProjectsMain
