import React from 'react';
import commonStyle from '../../../style/common.module.css';


const ContactUsHeading = () => {

    return (
        <h2 className={commonStyle.title + ' ' + commonStyle.blackWord}>Напишите нам</h2>
    )
}

export default ContactUsHeading