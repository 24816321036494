import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import WI from './WI'
import * as serviceWorker from './serviceWorker'
import { HashRouter } from 'react-router-dom'

// var cors = require('cors')
// import cors from 'cors'
// WI.use(cors()) // cool now everything is handled!

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <WI />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
