import React from 'react';
import styles from './ProjectsText.module.css';

const ProjectsText = () => {

    return (
            <p className={styles.description}>Мы-команда  Web Infrastructure, людей, которые однажды собрались, чтобы заниматься своим любимым делом. Мы обеспечим Вам полный цикл создания продукта, от  стратегии и аналитики до внедрения и сопровождения, используя современные IT-решения.</p>
    )
}

export default ProjectsText