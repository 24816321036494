import React, {useEffect, useState } from 'react';
import styles from './ProjectHeader.module.css'

import getState from '../../API/getState';
import commonStyles from '../../style/common.module.css';

const ProjectsHeader = (props) => {

    const [companyName, setCompanyName] = useState([]);
    const [companyDescription, setCompanyDescription] = useState([]);
    const [companyTechStackIcons, setCompanyTechStackIcons] = useState([]);
    const [companyHashtags, setCompanyHashtags] = useState([]);
    const [companyResults, setCompanyResults] = useState([]);
    const [companySolutions, setCompanySolutions] = useState([]);
    const [companyProjectTasks, setCompanyProjectTasks] = useState([]);

    const [companyNameList, setCompanyNameList] = useState([]);
    const [companyDescriptionList, setCompanyDescriptionList] = useState([]);
    const [companyTechStackIconsList, setCompanyTechStackIconsList] = useState([]);
    const [companyHashtagsList, setCompanyHashtagsList] = useState([]);

    const [companyResultsList, setCompanyResultsList] = useState([]);
    const [companySolutionsList, setCompanySolutionsList] = useState([]);
    const [companyProjectTasksList, setCompanyProjectTasksList] = useState([]);

    const [projectArrowTitle, setProjectArrowTitle] = useState('Следующий проект');

    useEffect(() => {

        const fetchData = async () => {
            const data = await getState('https://api.webinfra.ru/portfolio/?format=json');

            setCompanyName(data.data[0].title)
            setCompanyDescription(data.data[0].description)
            setCompanyTechStackIcons(data.data[0].tech_stack_icons)
            setCompanyHashtags(data.data[0].hashtags)
            setCompanyResults(data.data[0].results)
            setCompanySolutions(data.data[0].our_solutions)
            setCompanyProjectTasks(data.data[0].project_tasks)

            const arrayWithNames = []
            const arrayWithDescriptions = []
            const arrayWithTechStackIcons = []
            const arrayWithHashtags = []
            const arrayWithResults = []
            const arrayWithSolutions = []
            const arrayWithProjectTasks = []

            data.data.forEach((el) => {
                arrayWithNames.push(el.title)
                arrayWithDescriptions.push(el.description)
                arrayWithTechStackIcons.push(el.tech_stack_icons)
                arrayWithHashtags.push(el.hashtags)
                arrayWithResults.push(el.results)
                arrayWithSolutions.push(el.our_solutions)
                arrayWithProjectTasks.push(el.project_tasks)
            })

            setCompanyNameList(arrayWithNames)
            setCompanyDescriptionList(arrayWithDescriptions)
            setCompanyTechStackIconsList(arrayWithTechStackIcons)
            setCompanyHashtagsList(arrayWithHashtags)
            setCompanyResultsList(arrayWithResults)
            setCompanySolutionsList(arrayWithSolutions)
            setCompanyProjectTasksList(arrayWithProjectTasks)


        }

        fetchData();
    }, [])

    useEffect(() =>{
        props.updateData(companyResults, companySolutions, companyProjectTasks)
    })

    function loadNextCompanyData(e) {

        e.preventDefault()
        
            changeNextStateValue(companyName, companyNameList, setCompanyName)
            changeNextStateValue(companyDescription, companyDescriptionList, setCompanyDescription)
            changeNextStateValue(companyTechStackIcons, companyTechStackIconsList, setCompanyTechStackIcons)
            changeNextStateValue(companyHashtags, companyHashtagsList, setCompanyHashtags)
            changeNextStateValue(companyResults, companyResultsList, setCompanyResults)
            changeNextStateValue(companySolutions, companySolutionsList, setCompanySolutions)
            changeNextStateValue(companyProjectTasks, companyProjectTasksList, setCompanyProjectTasks)
    }
        
    function changeNextStateValue(value, valueList, key) {
        
        valueList.filter((el, index) => {
            if (el === value) {
                key(valueList[index + 1])
                setProjectArrowTitle('Следующий проект')
                    if (index === (valueList.length - 1)) {
                            key(valueList[(valueList.length - 1)])
                            setProjectArrowTitle('Предыдущий проект')
                    }
                    if (index === (valueList.length - 2)) {
                            setProjectArrowTitle('Предыдущий проект')
                    }
            }
        })
    }
    
    function loadPreviousCompanyData(e) {

                e.preventDefault()
        
                changePreviousStateValue(companyName, companyNameList, setCompanyName)
                changePreviousStateValue(companyDescription, companyDescriptionList, setCompanyDescription)
                changePreviousStateValue(companyHashtags, companyHashtagsList, setCompanyHashtags)
                changePreviousStateValue(companyTechStackIcons, companyTechStackIconsList, setCompanyTechStackIcons)
                changePreviousStateValue(companyResults, companyResultsList, setCompanyResults)
                changePreviousStateValue(companySolutions, companySolutionsList, setCompanySolutions)
                changePreviousStateValue(companyProjectTasks, companyProjectTasksList, setCompanyProjectTasks)
            }
        
    function changePreviousStateValue(value, valueList, key) {
        
                valueList.filter((el, index) => {
                    if (el === value) {
                            key(valueList[index -1])
                            setProjectArrowTitle('Следующий проект')
                        if (index === 0) {
                            key(valueList[0])
                        }
                    }
                })
            }

    let renderHashtags = companyHashtags.map((el, i)=>{ return <p key={i}>{el.title}</p> })
    let renderTechStackIcons = companyTechStackIcons.map((el, i)=>{ return <img key={i} className={styles.icons} src={el.href} alt={el.title}/> })

    return (
                    <section className={commonStyles.main}>
                <div className={commonStyles.mainWrapper}>

                    <div className={styles.wrapper}>
                        <div className={styles.padding}>
                            <h2 className={styles.title} >
                                {companyName}
                            </h2>
                            <p className={styles.description}>{companyDescription}</p>
                            <div className={styles.techStackIcons}>
                                {renderTechStackIcons}
                            </div>
                        </div>
                        <div className={styles.padding + ' ' + styles.buttonsAndHashtagsWrapper}>
                            <div>
                                <button className={styles.button}
                                        onClick={loadPreviousCompanyData}
                                >&larr;</button>
                                <button className={styles.button + ' ' + styles.buttonGrey}
                                        onClick={loadNextCompanyData}
                                >&rarr;</button>
                            </div>

                            <p className={styles.arrowText}>{projectArrowTitle}</p>
                            <div className={styles.hashtags}>
                                {renderHashtags}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
    )
}


export default ProjectsHeader