import React from 'react';
import commonStyle from '../../../style/common.module.css'


const ProjectsHeading = () => {

    return (
            <h2 className={commonStyle.title + ' ' + commonStyle.whiteWord}>Наши проекты</h2>
    )
}

export default ProjectsHeading