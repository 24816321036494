import React from 'react';
import styles from './PartnersLogos.module.css';

import bastion from '../../../pages/Main/images/partners-logo/bastion.svg';
import gazprom from '../../../pages/Main/images/partners-logo/gazprom.svg';
import infocompas from '../../../pages/Main/images/partners-logo/infocompas.svg';
import mail from '../../../pages/Main/images/partners-logo/mail.svg';
import McKinsey from '../../../pages/Main/images/partners-logo/McKinsey.svg';
import programm from '../../../pages/Main/images/partners-logo/programm.svg';
import rambler from '../../../pages/Main/images/partners-logo/rambler.svg';

const PartnersLogos = () => {

    const logos = [
        { image: bastion },
        { image: programm },
        { image: mail },
        { image: gazprom },
        { image: rambler },
        { image: infocompas },
        { image: McKinsey }
    ]

    let renderLogos = logos.map((el, index) => {
        return <img className={styles.img} src={el.image} alt='' key={index} />
    });
    return (
        <div className={styles.logosContainer}>
            {renderLogos}
        </div>
    )
}

export default PartnersLogos