const axios = require('axios');

export default function getState(url) {
    console.log('ANSWER');
    return axios({

        url: url,
        method: 'GET',
        responseType: 'json'
    })

}


// export default function getPortfolio (response){
//     response.then(
//         (data) => {
//                             // setCompanyName(data.data[0].title)
//                             // setCompanyDescription(data.data[0].description)
//                             // setCompanyTechStackIcons(data.data[0].tech_stack_icons)
//                             // setCompanyHashtags(data.data[0].hashtags)

//                             // const arrayWithNames = []
//                             // const arrayWithDescriptions = []
//                             // const arrayWithtechStackIcons = []
//                             // const arrayWithHashtags = []

//                             // data.data.forEach((el) => {
//                             //     arrayWithNames.push(el.title)
//                             //     arrayWithDescriptions.push(el.description)
//                             //     arrayWithtechStackIcons.push(el.tech_stack_icons)
//                             //     arrayWithHashtags.push(el.hashtags)
//                             // })

//                             // setCompanyNameList(arrayWithNames)
//                             // setCompanyDescriptionList(arrayWithDescriptions)
//                             // setCompanyTechStackIconsList(arrayWithtechStackIcons)
//                             // setCompanyHashtagsList(arrayWithHashtags)
//         })
//         }
//     )
// }

// url: 'http://api.webinfra.ru/portfolio/?format=json'

 