import React from 'react'
import axios from 'axios'

function postFormData(url, data, doOnUploadProgress, doAfterRequest) {
  console.log('in postFormData')
  console.log(data.get('brief'))
  axios({
    url: url,
    method: 'POST',
    data: data,
    onUploadProgress: doOnUploadProgress,
  })
    .then(() => {
      doAfterRequest()
      console.log('POST executed')
    })
    .catch((error) => {
      console.log(error)
    })
}

export default postFormData
