import React from 'react';
import styles from './HeaderButton.module.css'

import { NavHashLink as Link } from 'react-router-hash-link';



const HeaderButton = () => {

    return (
        <div>
            <Link style={{ all: 'unset' }} smooth to='/#sectionContactUs'><div className={styles.button}>Оставить заявку</div></Link>
        </div>
    )
}

export default HeaderButton
