import React from 'react';
import styles from './ContactUs.module.css';

import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsHeading from "./ContactUsHeading/ContactUsHeading";



const ContactUs = () => {

    return (
        <section id='sectionContactUs' className={styles.contactUs}>
            <ContactUsHeading />
            <div className={styles.wrapper}>
                <p className={styles.title}>Все просто! Оставьте заявку и наш специалист свяжется с Вами для обсуждения деталей проекта</p>
                <p className={styles.subtitle}>Контактные данные</p>
            </div>
            <ContactUsForm />
        </section>
    )
}

export default ContactUs