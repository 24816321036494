import React, { useRef, useContext } from 'react';
import styles from './ContactUsForm.module.css';

import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import MaskedInput from 'react-input-mask';

import attach from "../../../pages/Main/images/attach/attach.png";
import postFormData from "../../../API/postFormData"
import Context from '../../../context/context'




function disableButton(formRefs) {
    formRefs.button.current.disabled = 'true';
    formRefs.button.current.style.background = '#c0c0c0';
    formRefs.button.current.style.color = '#d5d5d5';
}

function hideTextAndShowApprovedText(formRefs, delayShort) {
    setTimeout(() => {
        formRefs.text.current.style.display = 'none';
        formRefs.approvedText.current.style.display = 'block';
    }, delayShort)
}


function resetAllForms(formRefs, document, delayLong) {
    setTimeout(() => {
        document.forms[0].reset();
        formRefs.dragAndDropContainer.current.style.display = 'flex';
        formRefs.acceptedFiles.current.style.display = 'none';
        formRefs.approvedText.current.style.display = 'none';
        formRefs.text.current.style.display = 'block';
        formRefs.button.current.removeAttribute('disabled');
        formRefs.button.current.style.background = '#E13837';
        formRefs.button.current.style.color = '#FFFFFF';
    }, delayLong)
}

let global_file = null

const ContactUsForm = (props) => {
    const { urlPostFormData } = useContext(Context);
    
    const { register, handleSubmit, errors } = useForm();

    const renderUploadFile = () => {
        formRefs.dragAndDropContainer.current.style.display = 'none'
        formRefs.acceptedFiles.current.innerHTML =
            `<img src='https://static.tildacdn.com/tild3764-3530-4161-b865-363066663163/photo.png' class=${styles.imgAccept} alt=''></img>
            <p>Файл загружен</p>`
    }
    
    function onDrop(file) {
        global_file = file[0]
        renderUploadFile()
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const formRefs = {
        ref: useRef(),
        text: useRef(),
        approvedText: useRef(),
        button: useRef(),
        dragAndDropContainer: useRef(),
        acceptedFiles: useRef(),
    }
    
    const delays = {
        short: 2000,
        long: 5000,
    }
    
    const doAfterRequest = () => {
        hideTextAndShowApprovedText(formRefs, delays.short)
        resetAllForms(formRefs, document, delays.long)
    }

    const doOnUploadProgress = () => {
        disableButton(formRefs)
    }

    const onSubmit = () => {
        let formData = new FormData(formRefs.ref.current)
        
        if (global_file){
            formData.set('brief', global_file)
        }
        
        postFormData(
            urlPostFormData, 
            formData, 
            doOnUploadProgress,
            doAfterRequest,
        )
    };

   
    return (
        <form ref={formRefs.ref} className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div >
                <div className={styles.inputsSection}>
                    <div>
                        <input className={styles.textInputX}
                            name='name'
                            type='text'
                            placeholder='Имя'
                            ref={register({ required: true })}
                            style={{ borderColor: errors.name && "red" }}
                        />
                        {errors.name && <p className={styles.errorMessage}>Введите имя</p>}
                    </div>
                    <div>
                        <MaskedInput mask='+7(999)999-99-99'>
                            {() => {
                                return <input
                                    name='phone_number'
                                    type='tel'
                                    ref={register({ required: true })}
                                    placeholder='Телефон'
                                    style={{ borderColor: errors.phone && "red" }}
                                    className={styles.textInputY}
                                />
                            }}
                        </MaskedInput>
                        {errors.phone_number && <p className={styles.errorMessage}>Введите номер телефона</p>}
                    </div>
                    <div>
                        <input className={styles.textInputZ}
                            name='email'
                            type='email'
                            placeholder='E-mail'
                            ref={register({ required: true })}
                            style={{ borderColor: errors.email && "red" }}
                        />
                        {errors.email && <p className={styles.errorMessage}>Введите e-mail</p>}
                    </div>
                    <div>
                        <input className={styles.textInputQ}
                            name='company'
                            type='text'
                            placeholder='Компания'
                            ref={register}
                        />
                    </div>
                </div>
            
                <div className={styles.uploadContainer}>
                    <div {...getRootProps()} className={styles.dashedWrapper} >
                        <div ref={formRefs.dragAndDropContainer}>
                            <img className={styles.clipImg} src={attach} alt='attach' />
                            <div>
                                <input
                                    {...getInputProps()}
                                    className={styles.fileInput}
                                    name='brief'
                                    type='file'
                                    multiple={false}
                                    ref={register}
                                    id='file_id'
                                />
                                <span>Перетащите сюда файл или </span>
                                <label htmlFor='file_id'>загрузите</label>
                            </div>
                        </div>
                        <div ref={formRefs.acceptedFiles}></div>
                    </div>
                </div>
                
                <div>
                    <textarea
                        name='message'
                        className={styles.textArea}
                        placeholder='Сообщение'
                        ref={register({ required: true })}
                        style={{ borderColor: errors.message && "red" }}
                    />
                    {errors.message && <p className={styles.errorMessage}>Введите сообщение</p>}
                </div>

            </div>

            {/* <div className={styles.radioBtnGroup}>
                <h4 className={styles.radioBtnGroupTitle} style={{ color: errors.projectType && "red" }}>Выберите тип Вашего проекта</h4>
                <div key={'divForServiceListOuter'}>
                    {firstStepSectionData.map((option) => {
                        return (
                            <div className={styles.formRadioBtn} key={`divForServiceList${option.id}`}>
                                <input
                                    id={`serviceListForThirdStepSection_${option.id}`}
                                    type='radio'
                                    name='projectType'
                                    value={'SERVICE_ID!!!!!'}
                                    ref={register({ required: true })}
                                />
                                <label htmlFor={`serviceListForThirdStepSection_${option.id}`}>{option.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div> */}

            <h4 ref={formRefs.text} className={styles.text}>Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с Политикой конфиденциальности</h4>
            <h3 ref={formRefs.approvedText} className={styles.approvedText}>Заявка успешно отправлена, мы свяжемся с Вами в ближайшее время!</h3>
            <button ref={formRefs.button} type='submit' className={styles.button + ' ' + styles.buttonHover}>Отправить</button>
        
        </form>
    )
}

export default ContactUsForm
