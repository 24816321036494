import React from 'react';
import styles from './ServicesCardsContainer.module.css';

import ServicesCard from './ServicesCard/ServicesCard';

import card1 from '../../../pages/Main/images/services/card1.png'
import card2 from '../../../pages/Main/images/services/card2.png'
import card3 from '../../../pages/Main/images/services/card3.png'
import card4 from '../../../pages/Main/images/services/card4.png'
import card5 from '../../../pages/Main/images/services/card5.png'
import card6 from '../../../pages/Main/images/services/card6.png'

const ServicesCardsContainer = () => {



    const cardsData = [

        { image: `url(${card1})`, color: '#6697B9', title: 'Консультация', description: 'Оставьте заявку-свяжемся   с Вами, проконсультируем и ответим на Ваши вопросы' },
        { image: `url(${card2})`, color: '#515875', title: 'Веб разработка', description: 'Разрабатываем сайты, корпоративные порталы, системы любой сложности' },
        { image: `url(${card3})`, color: '#7184AB', title: 'Дизайн (UX/UI)', description: 'Глубокая аналитика функционала и интерфейса, разработка фирменных стилей бренда ' },
        { image: `url(${card4})`, color: '#576F9E', title: 'CRM, ERP, HRM', description: 'Внедряем и дорабатываем системы с понятными пользователю интерфейсами' },
        { image: `url(${card5})`, color: '#4B6F88', title: 'IOS и Android', description: 'Приложения, как нативные, так и кроссплатформенные, на основе React Native' },
        { image: `url(${card6})`, color: '#9BA1BE', title: 'Решения с ИИ', description: 'Создаем решения на основе искусственного интелекта и машинного обучения' }
    ]

    let renderCards = cardsData.map((el, index) => {

        return <ServicesCard image={el.image} title={el.title} description={el.description} color={el.color} bottom={el.bottom} paddingBottom={el.paddingBottom} width={el.width} key={index} />
    });

    return (
        <div className={styles.cards}>
            {renderCards}
        </div>
    )
}

export default ServicesCardsContainer