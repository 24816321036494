import React from 'react';
import styles from './TeamMainCard.module.css';

const TeamMainCard = (props) => {
    return (
        <div className={styles.bioInfoContainer}>
            <img src={props.srcSet} alt='' />
            <div className={styles.textInfoContainer}>
                <div className={styles.fullName}>{props.fullName}</div>
                <span className={styles.workPosition}>{props.workPosition}</span>
                <p className={styles.about}>{props.about}</p>
            </div>
        </div>

    )
}


export default TeamMainCard

